import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import React from 'react'
import OfficialCommonBtn from '../components/atoms/officialCommonBtn'
import SEO from '../components/atoms/seo'
import ContactFlow from '../components/molecules/contactFlow'
import OfficialCommonH3Set from '../components/molecules/officialCommonH3Set'
import OfficialContactLayout from '../components/organisms/officialContactLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'
import BreakPoints from '../styles/breakPoints'
import useRouteParam from '../hooks/useRouteParam'

const Wrapper = styled.main`
  font-size: 16px;
  height: 100%;
  .p-head-inner {
    padding-bottom: 60px;
    .p-nav-area {
      display: none;
    }
  }
  .p-top-data-wrap {
    background: #f9f3c4;
    width: 100%;
    max-width: 620px;
    margin: 0 auto 60px;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    p {
      margin: 0 0 20px;
    }
    button {
      margin: 0;
    }
  }
  ${BreakPoints.xLarge} {
    .p-head-inner {
      .p-nav-area {
        display: flex;
      }
    }
  }
`

/**
 * サービスサイト用問い合わせ完了
 * @constructor
 */
const ContactThanksPage = () => {
  const routeParam = useRouteParam('/service-faq')
  return (
    <>
      <OfficialLowHeader
        label="お問い合わせありがとうございます"
        description={
          'お問い合わせいただいた内容を確認し\n2～3営業日以内に、担当よりメールにて返信させていただきます。'
        }
      />
      <OfficialContactLayout>
        <SEO title="お問い合わせ完了" />
        <Wrapper className="thanks">
          <div className="p-section-inner">
            <ContactFlow className="now-03" />
            <OfficialCommonH3Set
              label="お問い合わせありがとうございます"
              description={
                'お問い合わせいただいた内容を確認し\n2～3営業日以内に、担当よりメールにて返信させていただきます。'
              }
            />
            <div className="p-top-data-wrap">
              <p>
                お客様からよくお問い合わせいただくご質問を、Q&amp;A形式でご案内しています。
              </p>
              <OfficialCommonBtn
                size="small"
                level="weak"
                onClick={() => {
                  navigate(routeParam)
                }}
              >
                よくあるご質問
              </OfficialCommonBtn>
            </div>
          </div>
        </Wrapper>
      </OfficialContactLayout>
    </>
  )
}

export default ContactThanksPage

export const pageQuery = graphql`
  query ContactThanksPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
